import React, { Component } from 'react';
import {winWidth, winHeight} from '../../util/px2_react'
import './index.css'
import {connect} from 'react-redux';
import {getDownloadData} from '../../actions/actions';

class XinKeDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        this.props.dispatch(getDownloadData('xinke'))
    }

    render() {
        return (
            <div>
                <img src={require('../../image/xinkebg.png')} alt='android' style={{
                    width: winWidth, height: winHeight
                }}/>
                <div className='butWrap'>
                    <img
                        alt='android'
                        src={require('../../image/xinkeAndroid.png')}
                        className='butStyle'
                        onClick={()=>{
                            this.props.xinkeAndroid && window.open(this.props.xinkeAndroid);
                        }}
                    />
                    <img
                        alt='android'
                        src={require('../../image/xinkeIos.png')}
                        className='butStyle'
                        onClick={()=>{
                            this.props.xinkeIos && window.open(this.props.xinkeIos);
                        }}
                    />
                </div>
            </div>
        );
    }
}
function select(store){
    return {
        xinkeAndroid: store.commonData.xinkeAndroid,
        xinkeIos: store.commonData.xinkeIos,
    }
}

export default connect(select)(XinKeDownload);
