import React from 'react';
import {
    Route,
    Switch,
    HashRouter,
  } from 'react-router-dom';
import './App.css';
import BindInviteCode from './container/BindScreen/BindInviteCode'
import DownloadWallet from './container/DownloadWallet/DownloadWallet'
import XinKeDownload from './container/XinKeDownload/XinKeDownload'
import HomeScreen from './container/HomeScreen'

function App() {
    return (
        <div className="App">
            <HashRouter>
                <Switch>
                    <Route path="/" exact component={HomeScreen}/>
                    <Route path="/thinkey" component={BindInviteCode}/>
                    <Route path="/download" component={DownloadWallet}/>
                    <Route path="/xinkedownload" component={XinKeDownload}/>
                </Switch>
            </HashRouter>
        </div>
    );
}

export default App;
