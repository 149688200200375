export function commonData (state = { androidUrl : '', iosUrl: '', xinkeAndroid: '', xinkeIos: '' }, action = {}){
    const {
        type
    } = action;
    switch (type) {
        case 'GET_DATA':
            let andr = '', iosr = '';
            if(action.data.length){
                for(let i = 0; i < action.data.length; i++){
                    if(action.data[i].name === 'download'){
                        if(action.data[i] && action.data[i].value){
                            iosr = action.data[i].value
                        }
                    }else if(action.data[i].name === 'androiddownload'){
                        if(action.data[i] && action.data[i].value){
                            andr = action.data[i].value
                        }
                    }
                }
            }
            return (Object.assign({}, state, {
                androidUrl: andr,
                iosUrl: iosr
            }));
        case 'GET_XINKE_DATA':
            let xinkeandr = '', xinkeiosr = '';
            if(action.data.length){
                for(let i = 0; i < action.data.length; i++){
                    if(action.data[i].name === 'xinkeandroid'){
                        if(action.data[i] && action.data[i].value){
                            xinkeandr = action.data[i].value
                        }
                    }else if(action.data[i].name === 'xinkeios'){
                        if(action.data[i] && action.data[i].value){
                            xinkeiosr = action.data[i].value
                        }
                    }
                }
            }
            return (Object.assign({}, state, {
                xinkeAndroid: xinkeandr,
                xinkeIos: xinkeiosr
            }));
        default: 
            return state
    }
}