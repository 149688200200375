export function getDownloadData(type) { // 异步获取游戏信息
    return dispatch => {
      fetch('https://public.thinkey.org/v1/wallet/poc/getconf?',{
            method: 'GET',
            mode: 'cors',
        }).then((response)=>{
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            const error = new Error(response.statusText,'http'+response.status);
            error.response = response;
            throw error;
        })
        .then((res)=>res.json())
        .then((data)=>{
            if(type === 'wallet'){
                dispatch({type: 'GET_DATA', data: data})
            }else if(type === 'xinke'){
                dispatch({type: 'GET_XINKE_DATA', data: data})
            }
        })
    }
};