import React, { Component } from 'react';
import {  message } from 'antd';
import './BindInviteCode.css';
import Modal from 'react-modal';
import {winWidth, winHeight} from '../../util/px2_react'
import Background from '../../image/bg.png';
import {getDownloadData} from '../../actions/actions';
import {connect} from 'react-redux';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content : {
        border:0,
        borderRadius: 0,
        padding: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root')
class BindInviteCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            veriCode: '',
            inviteCode: '',
            modalInviteCode: '',
            modalIsOpen: false,
            modalType: 1,    //1修改邀请码    2开启绑定
            time: 59,
            isClick: false,
            isBindClick: false,
        };
    }

    parseQueryString = (url) => {
        let paraString = '';
        if(url.indexOf("?") !== -1){
            paraString = url.split('thinkey?')[1].substring(0,6)
            // paraString = url.substring(url.indexOf("thinkey?") + 1, url.indexOf("thinkey?") + 7);
        }
        return paraString;
    }

    componentDidMount(){
        let invites = this.parseQueryString(document.location.toString());
        this.setState({
            inviteCode: invites
        })
        this.props.dispatch(getDownloadData())
    }

    componentWillUnmount(){
        this.timer && clearInterval(this.timer)
        this.linkTimer && clearInterval(this.linkTimer)
    }

    getVerification(){
        if(!this.state.phone){
            message.warning('请输入手机号', 2);
            return;
        }else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.state.phone))){
            message.warning('请输入正确格式的手机号', 2)
            return;
        }
        this.setState({
            isClick: true
        })
        this.timer = setInterval(()=>{
            if(this.state.time <= 0){
                this.timer && clearInterval(this.timer)
                this.setState({
                    isClick: false,
                    time: 59
                })
            }else {
                this.setState({
                    time: this.state.time - 1
                })
            }
        },1000)
        let url = `https://public.thinkey.org/v1/wallet/poc/action/getverificationcode?locale=zh-CN&phone=${this.state.phone}`;

        fetch(url,{
            method: 'GET',
            mode: 'cors',
        }).then((response)=>{
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            const error = new Error(response.statusText,'http'+response.status);
            error.response = response;
            throw error;
        })
        .then((res)=>res.json())
        .then((data)=>{
            console.log('get verification result !!',data);
            if(!data.eCode || data.eCode === 200){
                message.info('验证码已发送');
            }else if(data.eCode === 10000){
                message.error(data.eMsg,2)
            }else{
                message.error('验证码发送失败',2)
            }
        })
    }

    goOtherWindow(){
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isAndroid && !isiOS){
            window.open(this.props.androidUrl);
        }else if(!isAndroid && isiOS){
            window.open(this.props.iosUrl);
        }
    }

    bindPhone(){
        if(this.state.isBindClick){
            return;
        }
        if(!this.state.phone){
            message.warning('请输入手机号', 2);
            return;
        }else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.state.phone))){
            message.warning('请输入正确格式的手机号', 2);
            return;
        }else if(!this.state.veriCode){
            message.warning('请输入验证码', 2);
            return;
        }else if(!this.state.inviteCode){
            message.warning('请输入邀请码', 2);
            return;
        }
        this.setState({
            isBindClick: true
        })
        let obj = {
            phone:this.state.phone,
            code:this.state.veriCode,
            otherInviteCode:this.state.inviteCode
        }
        fetch('https://public.thinkey.org/v1/wallet/poc/bindinvitecode?locale=zh-CN',{
            method: 'POST',
            mode: 'cors',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(obj)
        }).then((response)=>{
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            const error = new Error(response.statusText,'http'+response.status);
            error.response = response;
            throw error;
        })
        .then((res)=>res.json())
        .then((data)=>{
            console.log('post bind invite code result !!',data);
            if(!data.eCode || data.eCode === 200){
                this.setState({
                    modalIsOpen: true,
                    modalType: 2
                })
                this.linkTimer = setTimeout(()=>{
                    this.setState({
                        modalIsOpen: false
                    })
                    this.goOtherWindow()
                },10000)
            }else if(data.eCode === 10000){
                message.warning(data.eMsg,2)
            }else{
                message.error('请求失败，请重试',2)
            }
            this.setState({
                isBindClick: false
            })
        })
    }

    closeModal(){
        this.setState({
            modalIsOpen:false
        })
    }

    inviteModal(){
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                // onRequestClose={this.closeModal.bind(this)}
                style={customStyles}
            >
                <div className="modalColumn">
                    <p style={{margin: '1rem', color: '#515D8A'}}>{this.state.modalType === 2 ? '恭喜参与成功，王者梦想已起航！快去下载Thinkey体验王者的感觉吧':'TUEFLY是您朋友邀请您参与生态共建的证明，您确认要修改吗？'}</p>
                    {this.state.modalType === 2 ? null :
                    <div  className='input_row' style={{margin: '1rem'}}>
                        <p className='gray_font' style={{textAlign:'left',color:'#515D8A'}}>新邀请码：</p>
                        <div className='modalInputWraps'>
                            <input
                                style={{outlineStyle: 'none', width: '6.5rem', color: '#8C96B9',fontSize: '0.8rem'}}
                                onChange={(e)=>{
                                    this.setState({
                                        modalInviteCode: e.target.value
                                    })
                                }}
                                value={this.state.modalInviteCode}
                            />
                        </div>
                    </div>}
                    <div className='buttonRow'>
                        <div className='buttonStyle' 
                        onClick={()=>{
                            if(this.state.modalType === 2){
                                this.linkTimer && clearTimeout(this.linkTimer)
                                this.setState({
                                    modalIsOpen:false
                                })
                                this.props.iosUrl && window.open(this.props.iosUrl);
                            }else {
                                this.setState({
                                    modalInviteCode: '',
                                    modalIsOpen:false
                                })
                            }
                        }}>{this.state.modalType === 2 ? 'IOS梦想起航' : '暂不修改'}</div>

                        <div style={{width: '0.08rem', height: '2.8rem', backgroundColor: '#F2F2F2'}}></div>

                        <div className='buttonStyle' style={{color: '#515D8A'}} 
                        onClick={()=>{
                            if(this.state.modalType === 2){
                                this.linkTimer && clearTimeout(this.linkTimer)
                                this.setState({
                                    modalIsOpen:false
                                })
                                this.props.androidUrl && window.open(this.props.androidUrl);
                            }else {
                                if(this.state.modalInviteCode){
                                    this.setState({
                                        inviteCode: this.state.modalInviteCode,
                                        modalIsOpen:false
                                    })
                                }else {
                                    message.warning('请输入新邀请码',2)
                                }
                            }
                        }}>{this.state.modalType === 2 ? '安卓梦想起航' : '确认修改'}</div>
                    </div>
                </div>
            </Modal>
        )
    }
    
    render() {
        
        return (
            <div style={{backgroundImage: `url(${Background})`,backgroundSize: 'cover', width: winWidth,paddingTop: '1.2rem', height: winHeight}}>
                <div className='wraps' style={{width: winWidth - 32, height: winHeight - 40}}>
                    <p className='black_font'>您的朋友诚邀您参与</p>
                    <p className='black_font'>Thinkey生态共建</p>
                    <div className='gray_font' style={{marginTop: '2.5rem', display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <p style={{marginRight: '1rem'}}>—</p>
                        {`从青铜到王者你只差一个TKM`}
                        <p style={{marginLeft: '1rem'}}>—</p>
                    </div>

                    <div className='input_row' style={{marginTop: '4rem'}}>
                        {/* <p className='gray_font' style={{width: '6.5rem', textAlign:'right'}}>手机号：</p> */}
                        <div className='inputWraps' style={{width: winWidth - 72}}>
                            <input
                                className="inputs"
                                placeholder='请输入您的手机号'
                                style={{outlineStyle: 'none', width: '10rem',color: '#8C96B9', fontSize: '0.9rem'}}
                                onChange={(e)=>{
                                    this.setState({
                                        phone: e.target.value
                                    })
                                }}
                                value={this.state.phone}
                            />
                            <div style={{fontSize:'0.9rem', color: this.state.isClick ? '#8C96B9' : '#515D8A',padding: 0}} onClick={()=>{
                                if(!this.state.isClick){
                                    this.getVerification()
                                }
                            }}>{this.state.isClick ? this.state.time + '秒后重试' : '获取验证码'}</div>
                        </div>
                    </div>

                    <div className='input_row' style={{marginTop: '3rem'}}>
                        {/* <p className='gray_font' style={{width: '6.5rem', textAlign:'right'}}>验证码：</p> */}
                        <div className='inputWraps' style={{width: winWidth - 72}}>
                            <input
                                className="inputs"
                                placeholder='请输入验证码'
                                style={{outlineStyle: 'none', width: '10rem',color: '#8C96B9', fontSize: '0.9rem'}}
                                onChange={(e)=>{
                                    this.setState({
                                        veriCode: e.target.value
                                    })
                                }}
                                value={this.state.veriCode}
                            />
                        </div>
                    </div>

                    <div  className='codeWrap' style={{width: winWidth - 72, marginTop: '2rem'}}>
                        <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                            <p className='gray_font'>好友邀请码：</p>
                            <p className='gray_font' style={{fontWeight: 'bold'}}>{this.state.inviteCode ? `【${this.state.inviteCode}】` : ''}</p>
                        </div>
                        <div style={{fontSize:'0.8rem', color: '#515D8A',padding: 0}} onClick={()=>{
                            this.setState({
                                modalIsOpen:true,
                                modalType: 1
                            })
                        }}>修改</div>
                    </div>

                    <div
                        style={{
                            width: '10rem',height: '2.5rem',
                            display:'flex',
                            color: '#FFFFFF',
                            alignItems:'center',justifyContent:'center',
                            backgroundColor: '#515D8A',
                            marginTop: '2rem', 
                            fontSize: '0.9rem'}}
                        onClick={()=>{
                            this.bindPhone()
                        }}
                    >开启王者梦想</div>
                    {this.inviteModal()}
                </div>
                
            </div>
        );
    }
}

function select(store){
    return {
        androidUrl: store.commonData.androidUrl,
        iosUrl: store.commonData.iosUrl,
    }
}

export default connect(select)(BindInviteCode);
