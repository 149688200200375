import React, { Component } from 'react';
import {winWidth, winHeight} from '../../util/px2_react'
import './index.css'
import {connect} from 'react-redux';
import {getDownloadData} from '../../actions/actions';

class DownloadWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        this.props.dispatch(getDownloadData('wallet'))
    }

    render() {
        return (
            <div>
                <img src={require('../../image/downloadbg.png')} alt='android' style={{
                    width: winWidth, height: winHeight
                }}/>
                <div className='butWrap'>
                        <img
                            alt='android'
                            src={require('../../image/downAndroid.png')}
                            className='butStyle'
                            onClick={()=>{
                                this.props.androidUrl && window.open(this.props.androidUrl);
                            }}
                        />
                        <img
                            alt='android'
                            src={require('../../image/downIos.png')}
                            className='butStyle'
                            onClick={()=>{
                                this.props.iosUrl && window.open(this.props.iosUrl);
                            }}
                        />
                    </div>

            </div>
        );
    }
}
function select(store){
    return {
        androidUrl: store.commonData.androidUrl,
        iosUrl: store.commonData.iosUrl,
    }
}

export default connect(select)(DownloadWallet);
