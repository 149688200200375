import React, { Component } from 'react';
import {winWidth, winHeight} from '../util/px2_react'
import Background from '../image/bg.png';

class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        
        return (
            <div style={{backgroundImage: `url(${Background})`,backgroundSize: 'cover', width: winWidth,paddingTop: '1.2rem', height: winHeight}}>
                TUKE钱包
            </div>
        );
    }
}

export default HomeScreen;
